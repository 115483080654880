import { useEffect } from "react"

const Logout = ({ location }) => {
  const { state } = location
  useEffect(() => {
    if (state && state.userName) {
      //delete session cookie
      document.cookie = "dashsess=''; expires= Thu, 21 Aug 2014 20:00:00 UTC"
      window.location.href = "dashadm/user/logout"
    } else {
      window.top.location.href = "/"
    }
  })
  return null
}

export default Logout
